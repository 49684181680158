import { createStore } from 'redux'

let sidebarShow = window.localStorage.sidebarShow;
if (!sidebarShow || sidebarShow === 'true') {
  sidebarShow = true
} else {
  sidebarShow = false
}
const initialState = {
  sidebarShow: sidebarShow
}
const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      window.localStorage.setItem('sidebarShow', rest.sidebarShow)
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
